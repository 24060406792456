import { OverlayScrollbarsComponentProps } from 'overlayscrollbars-react';

export enum Environment {
  DEV = 'dev',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const envOrder: Record<Environment, number> = {
  [Environment.DEV]: 1,
  [Environment.STAGING]: 2,
  [Environment.PRODUCTION]: 3,
};

export enum NetlifyContext {
  PRODUCTION = 'prodution',
  PREVIEW = 'deploy-preview',
  DEPLOY = 'branch-deploy',
}

export enum DIALOGS_IDS {
  CALL_DETAILS_SWAP_SPEAKERS = 'call-details-swap-speakers',
  CALL_DETAILS_HEADER = 'call-details-header',
  CALL_DETAILS_SPECTRUM = 'call-details-spectrum',
  CALL_DETAILS_SIDEBAR_SNIPPET_ADD_TO_LIBRARY = 'call-details-sidebar-snippet-add-to-library',
  CALL_DETAILS_SIDEBAR_SNIPPET_SHARE = 'call-details-sidebar-snippet-share',
  CALL_DETAILS_SIDEBAR_SNIPPET_LIBRARY_SHARE = 'call-details-sidebar-snippet-library-share',
  CALL_DETAILS_REVIEW_SUBMIT = 'call-details-review-submit',
  CALL_DETAILS_CREATE_NEW_CRM_CONTACT = 'call-details-create-new-crm-contact',
  CALL_DETAILS_SAVE_EMAIL_FOLLOW_UP_TEMPLATE = 'call-details-save-email-follow-up-template',
  CALL_DETAILS_GENERATE_EMAIL_MODAL = 'call-details-generate-email-modal',
  SETTINGS_REVIEW_RATING = 'settings-review-rating',
  SETTINGS_INTEGRATIONS_GOOGLE_TEST_REMINDER = 'settings-integrations-google-test-reminder',
  SETTINGS_CREATE_EDIT_TAG = 'settings=create-edit-tag',
  SETTINGS_ADD_DELETE_LICENCES = 'settings-add-delete-licences',
  SETTINGS_FREE_TRIAL_ADD_LICENCES = 'settings-free-trial-add-licences',
  SETTINGS_INTEGRATIONS_ADMIN = 'settings-integrations-admin',
  SETTINGS_LIVE_NOTE_ASSOCIATION = 'settings-live-note-association',
  SETTINGS_SUBSCRIPTION_SEND_EMAIL = 'settings-subscription-send-email',
  SETTINGS_TEMPLATE_UNLINK_ENTITY = 'settings-template-unlink-entity',
  SETTINGS_TEMPLATE_LINK_CUSTOM_FIELD = 'settings-template-link-custom-field',
  SETTINGS_CALL_REVIEW_TEMPLATE_DELETION = 'settings-call-review-template-deletion',
  SETTINGS_EMAIL_FOLLOW_UP_TEST = 'settings-email-follow-up-test',
  SETTINGS_EMAIL_FOLLOW_UP_GENERATE_EMAIL = 'settings-email-follow-up-generate-email',
  LIBRARY_ADD_FOLDER = 'library-add-folder',
  LIBRARY_ADD_SUBFOLDER = 'library-add-subfolder',
  LIBRARY_MOVE_TO = 'library-move-to',
  LIBRARY_MOVE_TO_TABLE_ROW = 'library-move-to-table-row',
  HOME_UPLOAD_CALL = 'home-upload-call',
  ANALYTICS_USER_EXPORT_DATA = 'analytics-user-export-data',
  SETTINGS_AI_ASSISTANT = 'settings-ai-assistant',
  BLOCKING_TEMPLATE_FORM_HEADER = 'blocking-template-form-header',
  SEE_AI_SCORING_EXAMPLE = 'see-ai-scoring-example',
  UPLOAD_USERS = 'upload-users',
  DELETE_EMAIL = 'delete-email',

  // Confirmation Dialogs
  DELETE_CALL = 'delete-call',
  DELETE_COMMENT = 'delete-comment',
  DELETE_LIBRARY_COMMENT = 'delete-library-comment',
  DELETE_LIBRARY_ITEM = 'delete-library-item',
  DELETE_LIBRARY_FOLDER = 'library-library-folder',
  DELETE_TAG_TOPIC = 'tag-topic',
  DELETE_TEAM = 'delete-team',
  DELETE_USER = 'delete-user',
  DELETE_INTEGRATION = 'delete-integration',
  DELETE_REVIEW = 'delete-review',
  SUMMARY_TEMPLATE_DELETE_CONFIRM = 'summary_template_delete_confirm',
  SUMMARY_TEMPLATE_PUBLISH_CONFIRM = 'summary_template_publish_confirm',
  HIGHLIGHTS_TEMPLATE_DELETE_CONFIRM = 'highlights_template_delete_confirm',
  HIGHLIGHTS_TEMPLATE_PUBLISH_CONFIRM = 'highlights_template_publish_confirm',
  TOPIC_QUIT_FORM_VALIDATION = 'topic-quit-form-validation',
  LIVE_NOTE_CALL_ASSOCIATION_VALIDATION = 'live-note-call-association-validation',
  TEMPLATE_FORM_VALIDATION = 'template-form-validation',
  LICENCE_LAST_VALIDATION = 'licence-last-validation',
  CALL_REVIEW_VALIDATION = 'call-review-validation',
  TEAM_VALIDATION = 'team-validation',
  DISABLE_AI_ASSISTANT = 'disable-ai-assistant',
  CREATE_SUBSCRIPTION_VALIDATION = 'create-subscription-validation',
  REMINDER_EMAIL_TEMPLATE_VALIDATION = 'reminder-email-template-validation',
  REMINDER_EMAIL_SECTION_VALIDATION = 'reminder-email-section-validation',
  DOMAIN_NAMES_FORM_VALIDATION = 'domain-names-form-validation',
  CALL_IMPORT_RESTRICTIONS_FORM_VALIDATION_FORCE_EXIT = 'call-import-restrictions-form-validation-force-exit',
  DELETE_CUSTOM_VOCABULARY_VALUE = 'delete-custom-vocabulary-value',
  HOME_ADD_RECORDER_BOT_LIVE_MEETING = 'home-add-recorder-bot-live-meeting',
  CREATE_DEAL_VIEW_VALIDATION = 'create-deal-view-validation',
  CREATE_VIEW_VALIDATION = 'create-view-validation',
  PUBLIC_PAGE_ACCESS_ANONYMOUS_VALIDATION = 'public-page-access-anonymous-validation',
  DELETE_CRM_FILLING_PLAYGROUND_TESTS = 'delete-crm-filling-playground-tests',
  DEAL_WARNINGS_SETUP_VALIDATION = 'deal-warnings-setup-validation',
  RECORDER_DIALOG = 'recorder-dialog',
  PHYSICAL_MEETING_KEEP_RECORDING = 'physical-meeting-keep-recording',
  MOBILE_NOTIFICATIONS = 'mobile-notifications',
  MOBILE_WATCH_LATER = 'mobile-watch-later',
  MOBILE_TAG_DRAWER = 'mobile-tag-drawer',
  DELETE_DEAL_CRM_FIELD = 'delete-deal-crm-field',
}

export const CALL_FILE_EXTENSION_SUPPORTED = ['wav', 'mp3', 'm4a', 'mp4'];

export const GOOGLE_MEET_ADMIN_SETTINGS_URL = 'https://admin.google.com/ac/appsettings/725740718362/videoSettings';
export const AIRCALL_ADMIN_SETTINGS_URL = 'https://dashboard.aircall.io/integrations/api-keys';

export const DEFAULT_CALL_HISTORY_PAGE_SIZE = 5;

export const scrollbarDefaultOptions: OverlayScrollbarsComponentProps['options'] = {
  scrollbars: { autoHide: 'scroll' },
  overflow: { x: 'hidden' },
};

export const AUTO_HIDE_SCROLLBARS_OPTIONS: OverlayScrollbarsComponentProps['options'] = {
  scrollbars: { autoHide: 'leave', autoHideDelay: 0 },
};

export enum AppSource {
  CHROME_EXTENSION = 'chrome-extension',
  WEBAPP = 'web-app',
}

export const UNKNOWN_SPEAKER_LABEL = 'Unknown speaker';

export const SEARCH_CALL_DEBOUNCE_TIME_IN_MS = 300;
