import React from 'react';

import { useTranslation } from 'react-i18next';

import { IntercomIds } from '@/enums/intercom-ids';
import IntercomIcon from '@/static/intercom_filled.svg?react';

import { ButtonFilled } from '../common/ui-components/buttons/UIButtonFilled';

export const DealFeatureDisablePage = () => {
  const { t } = useTranslation('deals');
  return (
    <div className="flex h-full flex-col items-center justify-center gap-12 px-[25%]">
      <img className="h-80" src="/images/DealsPipelineDisable.svg" alt="deal feature preview" />
      <div className="flex w-full flex-col items-center">
        <span className="mb-6 rounded-md  bg-gradient-to-r from-lavender via-lavender to-pale-lavender px-3 py-0.5 text-base font-semibold uppercase">
          {t('feature.disable.title')}
        </span>
        <span className="mb-2 text-xl font-semibold">{t('feature.disable.subtitle')}</span>
        <span className="mb-10 text-center text-base font-normal text-grey-800">
          {t('feature.disable.description')}
        </span>
        <ButtonFilled icon={<IntercomIcon />} id={IntercomIds.DealDisabled}>
          {t('feature.disable.button')}
        </ButtonFilled>
      </div>
    </div>
  );
};
