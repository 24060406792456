import { useCurrentUser } from '@/entities/users/users.hooks';
import { Features } from '@/enums/features';

import { useIsCrmSupportedForDeals } from './useIsCrmSupportedForDeals';

export const useHideModjoDeals = () => {
  const currentUser = useCurrentUser();
  const isCRMsupportedForDeals = useIsCrmSupportedForDeals();
  return !isCRMsupportedForDeals || !currentUser?.hasFeature(Features.MODJO_DEALS);
};
