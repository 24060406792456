import { NavbarTab, RoutePaths } from '@/enums';
import BookmarkFilledIcon from '@/static/icons/basic/bookmark-Filled.svg?react';
import BookmarkLineIcon from '@/static/icons/basic/bookmark-Line.svg?react';
import HomeFilledIcon from '@/static/icons/basic/home-smile-Filled.svg?react';
import HomeLineIcon from '@/static/icons/basic/home-smile-Line.svg?react';
import ChartColumnFilledIcon from '@/static/icons/editor/chart-column-Filled.svg?react';
import ChartColumnLineIcon from '@/static/icons/editor/chart-column-Line.svg?react';
import DashboardFilledIcon from '@/static/icons/electronics/dashboard-Filled.svg?react';
import DashboardLineIcon from '@/static/icons/electronics/dashboard-Line.svg?react';
import DollarFilledIcon from '@/static/icons/finances/dollar-circle-Filled.svg?react';
import DollarLineIcon from '@/static/icons/finances/dollar-circle-Line.svg?react';
import { Event } from '@/tracking/events';

type NavigationKey = 'home' | 'deals' | 'explore' | 'analytics' | 'library' | 'settings';

export type NavigationLinkItem = {
  path: RoutePaths;
  alternatePaths?: RoutePaths[];
  navigationKey: NavigationKey;
  lineIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  filledIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  trackedEvent: { id: Event; data: object };
  beta?: boolean;
};

export const NAVIGATION_LINK_ITEMS: NavigationLinkItem[] = [
  {
    path: RoutePaths.HOME,
    navigationKey: 'home',
    lineIcon: HomeLineIcon,
    filledIcon: HomeFilledIcon,
    trackedEvent: { id: Event.CLICK_ON_NAVBAR, data: { navbarTab: NavbarTab.HOME } },
  },
  {
    path: RoutePaths.DEALS,
    navigationKey: 'deals',
    lineIcon: DollarLineIcon,
    filledIcon: DollarFilledIcon,
    trackedEvent: { id: Event.CLICK_ON_NAVBAR, data: { navbarTab: NavbarTab.DEALS_LIST } },
  },
  {
    path: RoutePaths.EXPLORE,
    alternatePaths: [RoutePaths.CALL_DETAILS],
    navigationKey: 'explore',
    lineIcon: DashboardLineIcon,
    filledIcon: DashboardFilledIcon,
    trackedEvent: { id: Event.CLICK_ON_NAVBAR, data: { navbarTab: NavbarTab.EXPLORE } },
  },
  {
    path: RoutePaths.ANALYTICS,
    navigationKey: 'analytics',
    lineIcon: ChartColumnLineIcon,
    filledIcon: ChartColumnFilledIcon,
    trackedEvent: { id: Event.CLICK_ON_NAVBAR, data: { navbarTab: NavbarTab.ANALYTICS } },
  },
  {
    path: RoutePaths.LIBRARY,
    navigationKey: 'library',
    lineIcon: BookmarkLineIcon,
    filledIcon: BookmarkFilledIcon,
    trackedEvent: { id: Event.CLICK_ON_NAVBAR, data: { navbarTab: NavbarTab.LIBRARY } },
  },
];
