import React from 'react';

import { isAxiosError } from 'axios';
import { LoaderFunctionArgs, RouteObject, defer, redirect } from 'react-router-dom';

import { apiService } from '@/api.service';
import { DealsLayout } from '@/components/deals/DealsLayout';
import { getPrivateCallDetailsQueryOtions } from '@/entities/call-details/call-details.queries';
import { RoutePaths } from '@/enums';
import { MainLayout } from '@/layout/MainLayout';
import { i18n } from '@/translation/i18n';
import { queryClient } from '@/utils/queryClient';
import { combineRoutes } from '@/utils/url-utils';
import { toNumber } from '@/utils/utils';

import { analyticsRoutes } from './AnalyticsRoutes';
import { settingsRoutes } from './SettingsRoutes';
import { ProtectedRoute } from '../ProtectedRoute';
import { RoutesErrorBoundary } from '../RoutesErrorBoundary';

const callDetailsRouteLoader = ({ params }: LoaderFunctionArgs<unknown>) => {
  const result: {
    call?: Promise<unknown>;
    i18n: Promise<unknown>;
  } = {
    i18n: i18n.loadNamespaces(['analytics', 'callDetails']),
  };
  // prefect call-details data in order to avoid glitch in the menu
  if (params.id) {
    const callId = toNumber(params.id);
    const defaultQueryOptions = getPrivateCallDetailsQueryOtions(callId);
    result.call = queryClient.prefetchQuery(defaultQueryOptions);
  }
  return defer(result);
};

export const privateRoutes: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    errorElement: <RoutesErrorBoundary />,
    children: [
      {
        path: RoutePaths.OAUTH_CALLBACK,
        async lazy() {
          const { OAuthConnectCallback } = await import('@/components/providers/oauth/OAuthConnectCallback');
          return { Component: OAuthConnectCallback };
        },
      },
      {
        path: RoutePaths.OAUTH_USERS,
        async lazy() {
          const { OAuthUserScopesCallBack } = await import('@/components/providers/oauth/OAuthUserScopesCallback');
          return { Component: OAuthUserScopesCallBack };
        },
      },
      {
        path: RoutePaths.POST_SIGN_ON_DISPATCHER,
        async lazy() {
          const { PostSignOnDispatcher } = await import('@/components/auth/PostSignOnDispatcher');
          return { Component: PostSignOnDispatcher };
        },
      },
      {
        path: RoutePaths.ONBOARDING,
        async lazy() {
          const { OnboardingPage } = await import('@/components/auth/OnboardingSteps');
          return {
            Component: OnboardingPage,
            loader: async () => {
              await i18n.loadNamespaces(['auth']);
              return null;
            },
          };
        },
      },
      {
        path: RoutePaths.EXPIRED_FREE_TRIAL,
        async lazy() {
          const { ExpiredFreeTrialPage } = await import('@/components/common/errors/ExpiredFreeTrialPage');
          return { Component: ExpiredFreeTrialPage };
        },
      },
      {
        element: <MainLayout />,
        children: [
          {
            path: RoutePaths.HOME,
            async lazy() {
              const { HomePage } = await import('@/components/home');
              return { Component: HomePage };
            },
            children: [
              {
                path: ':page',
                async lazy() {
                  const { HomepageCallTable } = await import('@/components/home/HomepageCallTable');
                  return { Component: HomepageCallTable };
                },
              },
            ],
          },
          {
            path: RoutePaths.CALL_DETAILS,
            async lazy() {
              const { CallDetailsPage } = await import('@/components/call/CallDetailsPage');
              return { Component: CallDetailsPage, loader: callDetailsRouteLoader };
            },
          },
          {
            path: RoutePaths.DEAL_CALL_VIEW,
            async lazy() {
              const { CallDetailsPage } = await import('@/components/call/CallDetailsPage');
              return { Component: CallDetailsPage, loader: callDetailsRouteLoader };
            },
          },
          {
            path: RoutePaths.EXPLORE,
            async lazy() {
              const { ExplorePage } = await import('@/components/explore');
              return {
                Component: ExplorePage,
                loader: async () => {
                  // make sure the namespace is loaded before rendering the related routes
                  await i18n.loadNamespaces(['explore', 'analytics']);
                  return null;
                },
              };
            },
          },
          {
            path: RoutePaths.REDIRECT_CRM_DEAL_TO_CRM_VIEW,
            loader: async () => {
              // make sure the namespace is loaded before rendering the related routes
              await i18n.loadNamespaces(['deals']);
              return null;
            },
            async lazy() {
              const { CrmDealRedirectPage } = await import('@/components/deals/CrmDealRedirect');
              return { Component: CrmDealRedirectPage };
            },
          },
          {
            element: <DealsLayout />,
            path: RoutePaths.DEALS,
            loader: async () => {
              // make sure the namespace is loaded before rendering the related routes
              await i18n.loadNamespaces(['deals']);
              return null;
            },
            children: [
              {
                index: true,
                async lazy() {
                  const { DealsPage } = await import('@/components/deals/RootPage');
                  return {
                    Component: DealsPage,
                    loader: async () => {
                      try {
                        const {
                          data: { nextCloseDate },
                        } = await apiService.API.get<{ nextCloseDate: string | null }>(
                          combineRoutes(['deals', 'next-close-date'])
                        );
                        return nextCloseDate;
                      } catch (error) {
                        if (isAxiosError(error) && error.response?.status === 401) {
                          redirect(RoutePaths.LOGIN);
                        }
                        return null;
                      }
                    },
                  };
                },
              },
              {
                path: ':id',
                async lazy() {
                  const { DealView } = await import('@/components/deals/DealView');
                  return { Component: DealView };
                },
              },
            ],
          },
          {
            path: RoutePaths.LIBRARY,
            async lazy() {
              const { LibraryLayout } = await import('@/components/library/LibraryLayout');
              return {
                Component: LibraryLayout,
                loader: async () => {
                  // make sure the namespace is loaded before rendering the related routes
                  await i18n.loadNamespaces(['library']);
                  return null;
                },
              };
            },
            children: [
              {
                path: `:id`,
                async lazy() {
                  const { LibraryPage } = await import('@/components/library');
                  return { Component: LibraryPage };
                },
              },
            ],
          },
          {
            path: RoutePaths.LIVENOTES_NOTES_LIST,
            async lazy() {
              const { NoteList } = await import('@/components/notes');
              return {
                Component: NoteList,
                loader: async () => {
                  await i18n.loadNamespaces(['note']);
                  return null;
                },
              };
            },
          },
          {
            path: RoutePaths.LAST_TRANSCRIBE_CALL,
            async lazy() {
              const { LatestTranscribedCallPage } = await import('@/components/explore/LatestTranscribedCallPage');
              return { Component: LatestTranscribedCallPage };
            },
          },
          analyticsRoutes,
        ],
      },
      settingsRoutes,
      {
        path: RoutePaths.NTA_DOWNLOAD_APP,
        loader: () => {
          return redirect(RoutePaths.MODJO_EXTENSION_SETTINGS);
        },
      },
    ],
  },
];
