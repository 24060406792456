import { useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import mixpanel from 'mixpanel-browser';

import { apiService } from '@/api.service';
import { useCallURLInfos } from '@/hooks/useCallURLInfos';
import { CustomSuspenseQueryOptions } from '@/types/common';
import { getFlagEmoji, getLanguageDisplayName } from '@/utils/language';
import { combineRoutes } from '@/utils/url-utils';

import { CALL_DETAILS_API_BASE_URL } from './call-details.constants';
import {
  CallDetails,
  PrivateCallDetails,
  PublicCallDetails,
  PublicSnippetCallDetails,
  isPublicSnippetCallDetails,
} from './call-details.types';
import { getCallDetailsQueryKeyFromCallUrlInfos } from './call-details.utils';
import { queryKeys } from '../queryKeys';
import { getPublicSnippetCallDetails } from '../snippets/snippets.queries';

const getPublicCallDetails = (uuid: string, tenantName: string, snippetId?: number) => async () => {
  if (snippetId) {
    return getPublicSnippetCallDetails<PublicSnippetCallDetails>(uuid, snippetId, tenantName)();
  }
  const { data } = await apiService.API.get<PublicCallDetails>(combineRoutes([CALL_DETAILS_API_BASE_URL, 'public']), {
    params: {
      tenantName,
      uuid,
    },
  });
  return data;
};

const getPrivateCallDetails = (callId: number) => async () => {
  if (!callId) {
    return {} as PrivateCallDetails;
  }
  const { data } = await apiService.API.get<PrivateCallDetails>(
    combineRoutes([CALL_DETAILS_API_BASE_URL, String(callId)])
  );
  return data;
};

export const getPrivateCallDetailsQueryOtions = (callId: number) => {
  const queryKey = queryKeys.callDetails.privateCall(callId);
  const queryFn = async () => {
    const callback = getPrivateCallDetails(callId);
    const newData = await callback();
    // We need to first make sure the apiLanguageCode is supported
    const languageFlag = newData.language && getFlagEmoji(newData.language);
    const languageDisplayName = newData.language && getLanguageDisplayName(newData.language);
    return {
      ...newData,
      id: callId,
      language: languageFlag && languageDisplayName ? newData.language : undefined,
    };
  };
  return {
    gcTime: Number.POSITIVE_INFINITY,
    queryKey,
    queryFn,
  };
};

export const useCallDetailsQuery = <TSelectData = CallDetails>(
  queryOptions?: CustomSuspenseQueryOptions<CallDetails, TSelectData>
) => {
  const queryClient = useQueryClient();
  const callURLInfos = useCallURLInfos();
  const queryKey = getCallDetailsQueryKeyFromCallUrlInfos(callURLInfos);
  const queryFn = async () => {
    const callback = callURLInfos.isPublic
      ? getPublicCallDetails(callURLInfos.uuid, callURLInfos.tenantName, callURLInfos.snippetId)
      : getPrivateCallDetails(callURLInfos.callId);
    const newData = await callback();
    // Make sure call `id` is always set
    const callId = callURLInfos.isPublicSnippet && isPublicSnippetCallDetails(newData) ? newData.callId : newData.id;
    // We need to first make sure the apiLanguageCode is supported
    const languageFlag = newData.language && getFlagEmoji(newData.language);
    const languageDisplayName = newData.language && getLanguageDisplayName(newData.language);
    if (callURLInfos.isPublic) {
      mixpanel.register_once({ tenant: newData.tenantName });
    }

    const previousData = structuredClone(queryClient.getQueryData<CallDetails>(queryKey));

    return {
      ...newData,
      id: callId,
      language: languageFlag && languageDisplayName ? newData.language : undefined,
      // If this is a refetch because stale data, we keep the previous mediaUrl because it changes
      // between every fetch. If not refect, we return the actual mediaUrl we got.
      // The only limit here is that the mediaUrl from AWS will be expired after 3 days.
      mediaUrl: previousData?.mediaUrl ?? newData.mediaUrl,
    };
  };
  return useSuspenseQuery<CallDetails, AxiosError>({
    retryDelay: 0,
    retry: 0,
    gcTime: Number.POSITIVE_INFINITY,
    queryKey,
    queryFn,
    ...queryOptions,
  });
};

export const usePublicCallQuery = (tenantName?: string, uuid?: string, enabled?: boolean) => {
  // we put default value for the params but it won't be used
  // without the proper value thanks to the enabled option
  const queryFn = getPublicCallDetails(uuid ?? '', tenantName ?? '');
  return useQuery<PublicCallDetails>({
    queryKey: queryKeys.callDetails.publicCall(uuid!, tenantName!),
    queryFn,
    enabled: !!enabled && !!uuid && !!tenantName,
  });
};
