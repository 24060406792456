import React, { Suspense } from 'react';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'overlayscrollbars/overlayscrollbars.css';
import './index.css';
// add polyfills for old browsers that terigger sentry errors
import 'core-js/actual/array';

import { Portal } from '@headlessui/react';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { domMax, LazyMotion } from 'framer-motion';
import mixpanel from 'mixpanel-browser';
import { createRoot } from 'react-dom/client';
import { withErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';
import { SkeletonTheme } from 'react-loading-skeleton';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';

import { apiService } from './api.service';
import { ErrorFallback, isChunkLoadError } from './components/common/errors/fallback';
import { ProgressPlaceholder } from './components/common/ProgressPlaceholder';
import { DialogContextProvider } from './components/common/ui-components/dialogs/DialogContextProvider';
import { IntercomMessengerLauncher } from './components/intercom';
import { ShortcutsContextProvider } from './components/MainSidebar/Search/SearchDialog/SearchContent/FiltersContentPopover/ShortcutsProvider';
import { SearchContextProvider } from './components/MainSidebar/Search/SearchDialog/SearchContent/SearchProvider';
import { ApiContext } from './contexts/api-context';
import { currentUserQueryObject } from './entities/users/users.queries';
import { ApiCurrentUser, ApiUser } from './entities/users/users.types';
import { DIALOGS_IDS, Environment } from './enums/constants';
import { LOCALSTORAGE_CHUNK_ERROR_IS_RELOADING } from './enums/localstorage-keys';
import { router } from './routes';
import { authenticationStore } from './stores/authentication.store';
import { twThemeColors } from './theme/colors';
import { mixpanelInit } from './tracking/mixpanel-init';
import { UserLanguageSetter } from './translation/UserLanguageSetter';
import { MultiProvider } from './utils/MultiProvider';
import { queryClient } from './utils/queryClient';

import './translation/i18n';

const GTM_TAG_ID = 'GTM-T25ZGSL';

const INTERCOM_APP_ID = 'rfvhv6nd';
const INTERCOM_APP_STAGING_ID = 'zfop3ks3';

// always delete chunk load reload state on app start
localStorage.removeItem(LOCALSTORAGE_CHUNK_ERROR_IS_RELOADING);

// logout if we are a login in  with a farmer token
if (globalThis.location.pathname.includes('farmer-auth/')) {
  apiService.authService.logout(false);
}

if (apiService.authService.hasRefreshToken) {
  // eslint-disable-next-line unicorn/prefer-top-level-await
  queryClient.prefetchQuery<ApiCurrentUser>(currentUserQueryObject).then(() => {
    const user = queryClient.getQueryData<ApiUser>(currentUserQueryObject.queryKey);
    if (user) {
      const userIdentifier = `${user.tenantName}_${user.id}`;
      mixpanel.identify(userIdentifier);
      mixpanel.people.set({
        $name: user.firstName && user.lastName ? `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim() : undefined,
        $email: user.email,
        tenant: user.tenantName,
        hasLicense: String(user.hasLicense),
        jobDepartment: user.jobDepartment,
        jobTitle: user.jobTitle,
        role: user.role,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (window.plantrack) {
        // @ts-expect-error eslint-disable-next-line @typescript-eslint/no-unsafe-call
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.plantrack.identify(userIdentifier, {
          email: user.email,
          tenantName: user.tenantName,
          userId: user.id,
        });
      }

      Sentry.setUser({ email: user.email, tenant: user.tenantName });
      Sentry.setTag('tenant', user.tenantName);

      TagManager.dataLayer({
        dataLayer: {
          event: 'USER_SIGNED_IN',
          email: user.email,
        },
      });
    } else {
      authenticationStore.setIsAuthenticated(false);
    }
    TagManager.initialize({
      gtmId: import.meta.env.VITE_GTM_ID ?? GTM_TAG_ID,
    });
  });
} else {
  authenticationStore.setIsAuthenticated(false);
}

const App = () => {
  return (
    <Suspense fallback={<ProgressPlaceholder />}>
      {/* eslint-disable-next-line tailwindcss/classnames-order, tailwindcss/enforces-shorthand */}
      <div className="fixed inset-0 flex flex-col">
        <IntercomProvider
          appId={import.meta.env.VITE_ENV === Environment.PRODUCTION ? INTERCOM_APP_ID : INTERCOM_APP_STAGING_ID}
          autoBoot
        >
          <QueryClientProvider client={queryClient}>
            <ApiContext.Provider value={apiService}>
              <>
                <IntercomMessengerLauncher />
                <UserLanguageSetter />
                <MultiProvider
                  providers={[
                    <LazyMotion features={domMax} strict />,
                    <SearchContextProvider />,
                    <DialogContextProvider dialogIds={Object.values(DIALOGS_IDS)} />,
                    <ShortcutsContextProvider />,
                    <SkeletonTheme borderRadius={4} baseColor={twThemeColors.lavender.DEFAULT} />,
                  ]}
                >
                  <RouterProvider router={router} fallbackElement={<ProgressPlaceholder />} />
                  <Portal>
                    <ToastContainer
                      className="flex-center z-[9999] max-w-full flex-col sm:w-max"
                      position="bottom-center"
                      newestOnTop
                      limit={3}
                      theme="colored"
                    />
                  </Portal>
                </MultiProvider>
              </>
            </ApiContext.Provider>
          </QueryClientProvider>
        </IntercomProvider>
      </div>
    </Suspense>
  );
};

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `modjo-webapp@${import.meta.env.VITE_VERSION ?? ''}`,
    environment: import.meta.env.VITE_ENV,
    tunnel: '/proxy/sentry',
    ignoreErrors: [
      'Network Error',
      'Request failed with status code 401',
      'AbortError: The operation was aborted.',
      'Request aborted',
      'NetworkError when attempting to fetch resource.',
      'Failed to fetch',
    ],
  });
}

mixpanelInit();

const AppWithErrorBoundary = withErrorBoundary(App, {
  fallbackRender: ({ error }) => (
    // eslint-disable-next-line tailwindcss/enforces-shorthand
    <div className="flex h-screen w-screen">{!isChunkLoadError(error as Error) && <ErrorFallback />}</div>
  ),
});

let container: HTMLElement | null = null;
document.addEventListener('DOMContentLoaded', () => {
  if (!container) {
    container = document.getElementById('modjo-root') as HTMLElement;
    const root = createRoot(container);
    root.render(
      <React.StrictMode>
        <AppWithErrorBoundary />
      </React.StrictMode>
    );
  }
});
