import React, { useState } from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Key } from 'ts-key-enum';
import { Drawer } from 'vaul';

import { scrollbarDefaultOptions } from '@/enums/constants';
import { removeElementAtIndex } from '@/utils/array-utils';
import { isEmpty } from '@/utils/utils';

import { MultiComboboxWithChips } from './MultiCombobox';
import { MultiComboboxWithChipsProps } from './types';
import { MultiComboboxTagChip } from '../../../TagStack/DesktopTagStack/MultiComboboxTagChip';
import { SelectMobileDrawerWrapper } from '../../select/SelectMobileDrawerWrapper';
import { getFilteredOptions } from '../common.helpers';

const OPTIONS_DROPDOWN_LIMIT = 100;

interface IHasColor {
  color: string;
}

function hasColorProperty<TValue>(obj: TValue): obj is TValue & IHasColor {
  return (obj as IHasColor).color !== undefined;
}

const DefaultMultiComboboxWithChips = <TValue extends { id: string; label: string; textDark?: boolean }>({
  options,
  values,
  setValues,
  onChange,
  label,
  onInputChange,
  error,
  placeholder,
  noResultLabel,
  disabled,
  defaultChips,
  alwaysDefaultChips = false,
}: MultiComboboxWithChipsProps<TValue>) => {
  const { t } = useTranslation('common');

  const [inputQuery, setInputQuery] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);

  const allFilteredOptions = getFilteredOptions<TValue>(options, inputQuery);
  const filteredOptions = allFilteredOptions.slice(0, OPTIONS_DROPDOWN_LIMIT);

  return (
    <div
      className="flex flex-col"
      role="button"
      tabIndex={0}
      onKeyDown={() => {
        if (isMobile) {
          setIsOpen(true);
        }
      }}
      onClick={() => {
        if (isMobile) {
          setIsOpen(true);
        }
      }}
    >
      <MultiComboboxWithChips value={values} onChange={onChange}>
        <>
          {label && <MultiComboboxWithChips.Label>{label}</MultiComboboxWithChips.Label>}
          <MultiComboboxWithChips.InputWithChips
            open={isOpen}
            onChange={(event) => {
              setInputQuery(event.target.value);
              onInputChange?.(event.target.value);
            }}
            onKeyDown={(event) => {
              if (isEmpty(inputQuery) && event.key === Key.Backspace && values.length > 0) {
                setValues(values.slice(0, -1)); // Remove the last element
              }
            }}
            onClick={() => {
              setIsOpen(true);
            }}
            placeholder={placeholder}
            error={error}
            disabled={disabled}
          >
            {defaultChips &&
              (alwaysDefaultChips || values.length === 0) &&
              defaultChips.map((chip, index) => {
                const { id, label: valueLabel, ...other } = chip;
                const hasColor = hasColorProperty<TValue>(chip);
                return (
                  <MultiComboboxTagChip
                    key={id}
                    label={valueLabel}
                    disabled={disabled}
                    color={hasColor ? chip.color : undefined}
                    textDark={chip.textDark}
                    tagIndex={index}
                    {...other}
                  />
                );
              })}
            {values.map((value, index) => {
              const { id, label: valueLabel, ...other } = value;
              const hasColor = hasColorProperty<TValue>(value);
              return (
                <MultiComboboxTagChip
                  key={id}
                  tagIndex={index}
                  label={valueLabel}
                  disabled={disabled}
                  onDelete={() => {
                    const updatedValues = removeElementAtIndex(values, index);
                    setValues(updatedValues);
                  }}
                  color={hasColor ? value.color : undefined}
                  textDark={value.textDark}
                  {...other}
                />
              );
            })}
          </MultiComboboxWithChips.InputWithChips>
          {isMobile ? (
            <Drawer.NestedRoot
              open={isOpen}
              onOpenChange={(_open) => {
                setIsOpen(_open);
              }}
              dismissible
            >
              <SelectMobileDrawerWrapper title={label}>
                <>
                  <div className="p-4">
                    <MultiComboboxWithChips.InputWithChips
                      open
                      onChange={(event) => {
                        setInputQuery(event.target.value);
                        onInputChange?.(event.target.value);
                      }}
                      onKeyDown={(event) => {
                        if (isEmpty(inputQuery) && event.key === Key.Backspace && values.length > 0) {
                          setValues(values.slice(0, -1)); // Remove the last element
                        }
                      }}
                      placeholder={placeholder}
                      error={error}
                      disabled={disabled}
                      hideIcon
                    />
                  </div>
                  <OverlayScrollbarsComponent
                    className="max-h-[500px] min-h-96 w-full p-4"
                    options={scrollbarDefaultOptions}
                  >
                    {!isEmpty(filteredOptions) &&
                      filteredOptions.map((option) => (
                        <MultiComboboxWithChips.Option key={option.id} value={option} as="li">
                          {option.label}
                        </MultiComboboxWithChips.Option>
                      ))}
                    {isEmpty(filteredOptions) && (
                      <li key="no-option" className="px-3 py-1">
                        <span>{noResultLabel ?? t('combobox.noOptionFound')}</span>
                      </li>
                    )}
                    {!isEmpty(inputQuery) && allFilteredOptions.length > filteredOptions.length && (
                      <span className="self-center py-1 text-grey-700">{t('combobox.continueTyping')}</span>
                    )}
                  </OverlayScrollbarsComponent>
                </>
              </SelectMobileDrawerWrapper>
            </Drawer.NestedRoot>
          ) : (
            <MultiComboboxWithChips.Options as="ul">
              {!isEmpty(filteredOptions) &&
                filteredOptions.map((option) => (
                  <MultiComboboxWithChips.Option key={option.id} value={option} as="li">
                    {option.label}
                  </MultiComboboxWithChips.Option>
                ))}
              {isEmpty(filteredOptions) && (
                <li key="no-option" className="px-3 py-1">
                  <span>{noResultLabel ?? t('combobox.noOptionFound')}</span>
                </li>
              )}
              {!isEmpty(inputQuery) && allFilteredOptions.length > filteredOptions.length && (
                <span className="self-center py-1 text-grey-700">{t('combobox.continueTyping')}</span>
              )}
            </MultiComboboxWithChips.Options>
          )}
        </>
      </MultiComboboxWithChips>
    </div>
  );
};

export { DefaultMultiComboboxWithChips };
