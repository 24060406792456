import React from 'react';

import { isAxiosError } from 'axios';
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCopyToClipboard } from 'react-use';

import { createToast } from '@/components/common/toast/ToastHelpers';
import { ButtonFilled } from '@/components/common/ui-components/buttons/UIButtonFilled';
import { ButtonOutlined } from '@/components/common/ui-components/buttons/UIButtonOutlined';
import { useActivityTimelineQuery } from '@/entities/activities/activities.queries';
import { useDealInfoQuery } from '@/entities/deals/deals.queries';
import { RoutePaths } from '@/enums';
import { HttpStatusCode } from '@/enums/http-status-code';
import { ProductTourIds } from '@/enums/product-tour-ids';
import AiStarsFilledIcon from '@/static/AiStars-Filled.svg?react';
import EditorLinkIcon from '@/static/icons/editor/link_45-Filled.svg?react';
import { EventOrigin, Event } from '@/tracking/events';
import { DataTestId } from '@/types/data-test-id';

import { AskAnythingPanel } from './DealAskAnything';
import { askAnythingStore } from './DealAskAnything/askAnythingStore';
import { DealSearchBar } from './DealSearchBar';
import { DealsBreadcrumb } from '../../../common/breadcrumbs/DealsBreadcrumb';

const DealsHeader = () => {
  const { t } = useTranslation();
  const [, copyToClipboard] = useCopyToClipboard();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dealId = id && Number.parseInt(id, 10);

  const dealInfoQuery = useDealInfoQuery(Number(dealId));
  const activityTimelineQuery = useActivityTimelineQuery({
    filters: { dealIds: [Number(dealId)] },
    queryOptions: { enabled: !!dealId },
  });

  if (
    dealInfoQuery.isError &&
    activityTimelineQuery.isError &&
    isAxiosError(dealInfoQuery.error) &&
    dealInfoQuery.error.response?.status === HttpStatusCode.NOT_FOUND
  ) {
    navigate(RoutePaths.NOT_FOUND, { replace: true });
    return null;
  }

  const pageOrigin = dealId ? EventOrigin.DEAL_VIEW : EventOrigin.DEALS_LIST;
  const hasCallInteraction = activityTimelineQuery.data?.some((activity) => activity.type === 'call');

  const handleClickCopyLink = () => {
    mixpanel.track(Event.DEALS_SHARE, {
      type: pageOrigin,
    });
    createToast(t('linkCopied'));
    return copyToClipboard(window.location.href);
  };

  return (
    <header className="flex items-center justify-between gap-x-1 border-b border-b-grey-100 px-10 py-3">
      <DealsBreadcrumb
        deal={
          dealInfoQuery.data
            ? {
                id: Number(dealId),
                name: dealInfoQuery.data.name,
              }
            : undefined
        }
        pageOrigin={pageOrigin}
      />

      <div className="flex items-center gap-x-2">
        {pageOrigin === EventOrigin.DEALS_LIST && <DealSearchBar />}

        {pageOrigin === EventOrigin.DEAL_VIEW && (
          <ButtonFilled
            size="small"
            icon={<AiStarsFilledIcon className="icon-small" />}
            iconPosition="start"
            disabled={!hasCallInteraction}
            tooltip={hasCallInteraction ? undefined : t('deals:dealView.askMeAnything.disabledTooltip')}
            className="pr-3 outline-none md:pr-4.5"
            onClick={() => {
              mixpanel.track(Event.DEAL_ASK_ANYTHING_CLICK_SIDEPANEL, {
                isOpen: true,
                from: EventOrigin.DEAL_VIEW,
              });
              dealId && askAnythingStore.setDealId(dealId);
              askAnythingStore.setIsRightPanelOpen(true);
            }}
            productTourId={hasCallInteraction ? ProductTourIds.DEAL_VIEW_ASK_ANYTHING_BUTTON : undefined}
          >
            {t('deals:dealView.askMeAnything.button')}
          </ButtonFilled>
        )}

        <ButtonOutlined
          size="small"
          className="pr-3 outline-none md:pr-4.5"
          icon={<EditorLinkIcon className="icon-small" />}
          onClick={handleClickCopyLink}
          dataTestId={DataTestId.DEALS_LIST_COPY_LINK_ID}
        >
          <span className="hidden first-letter:capitalize md:block">{t('copyLink')}</span>
        </ButtonOutlined>
      </div>
      <AskAnythingPanel />
    </header>
  );
};

export { DealsHeader };
