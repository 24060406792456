import React from 'react';

import { DealNoModjoAIContent } from '@/components/deals/common/DealNoModjoAiContent';

export const AskAnythingNoAI = () => {
  return (
    <div className="flex size-full items-center justify-center px-20">
      <div className=" flex flex-col items-center gap-6 rounded-xl bg-gradient-to-r from-lavender via-lavender to-pale-lavender px-14 py-8">
        <img className="w-48" src="/images/AskAnythingNoAi.svg" alt="No AI" />
        <DealNoModjoAIContent className="max-w-full items-center text-center" />
      </div>
    </div>
  );
};
