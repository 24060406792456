import React, { forwardRef } from 'react';

import { cn } from '@/utils/utils';

import { ButtonBase } from './ButtonBase';
import { IconButtonBase } from './IconButtonBase';
import { ButtonBaseProps, IconButtonBaseProps } from './types';

/**
 * Button with borders and white background.
 */
const CLASSES = {
  base: 'bg-grey-white text-grey-900',
  ring: 'ring-1 ring-grey-100 ring-inset active:focus:ring-0 active:focus:ring-offset-0 hover:focus:ring-0 hover:focus:ring-offset-0 focus:ring-1 focus:ring-transparent-basic-24',
  disabled: 'bg-grey-white cursor-not-allowed border-transparent-grey-16 text-grey-400 fill-grey-400',
  notDisabled: 'hover:bg-grey-100 active:bg-grey-200',
  fullWidth: 'w-full',
};

type ButtonOutlinedProps = ButtonBaseProps & {
  fullWidth?: boolean;
};

const ButtonOutlined = forwardRef<HTMLButtonElement, ButtonOutlinedProps>(
  ({ fullWidth, disabled, className, ...otherProps }, reference) => (
    <ButtonBase
      ref={reference}
      {...otherProps}
      disabled={disabled}
      className={cn(
        CLASSES.base,
        CLASSES.ring,
        disabled ? CLASSES.disabled : CLASSES.notDisabled,
        fullWidth && CLASSES.fullWidth,
        className
      )}
    />
  )
);

const IconButtonOutlined = forwardRef<HTMLButtonElement, IconButtonBaseProps>(
  ({ disabled, className, ...otherProps }, reference) => (
    <IconButtonBase
      ref={reference}
      {...otherProps}
      disabled={disabled}
      className={cn(CLASSES.base, CLASSES.ring, disabled ? CLASSES.disabled : CLASSES.notDisabled, className)}
    />
  )
);

export { ButtonOutlined, IconButtonOutlined };
