import { ObjectValues } from '@/types/common';

export const Features = {
  DELETE_CALL: 'DELETE_CALL',
  DOWNLOAD_RECORDING: 'DOWNLOAD_RECORDING',
  MODJO_DEALS: 'MODJO_DEALS',
  SYNC_EMAIL: 'SYNC_EMAIL',
  CRM_FILLING: 'CRM_FILLING',
  CRM_FILLING_AUTO_PUSH: 'CRM_FILLING_AUTO_PUSH',
  CRM_FILLING_NEW_TYPES: 'CRM_FILLING_NEW_TYPES',
  EMAIL_FOLLOW_UP: 'EMAIL_FOLLOW_UP',
};

export type Feature = ObjectValues<typeof Features>;
