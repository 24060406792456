import { useRef } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError, AxiosProgressEvent, HttpStatusCode } from 'axios';
import { Id, toast } from 'react-toastify';

import { apiService } from '@/api.service';
import { AlertVariant } from '@/components/common/toast/alert';
import { clearToast, createCustomToast, createToast } from '@/components/common/toast/ToastHelpers';
import { ToastMessage } from '@/components/common/toast/ToastMessage';
import { CallTagState, TagOrigin } from '@/enums';
import { i18n } from '@/translation/i18n';
import { combineRoutes } from '@/utils/url-utils';
import { showResponseAlert, sleep } from '@/utils/utils';

import { CALLS_API_BASE_URL } from './calls.constants';
import { updateCallItemInCallSearchCache } from './calls.helpers';
import { AiCallScoringDetails, ApiCallEntity, ApiUploadCall, ApiUploadCallResponse } from './calls.types';
import { PrivateCallDetails } from '../call-details/call-details.types';
import { updateCallDetailsCache } from '../call-details/call-details.utils';
import { ApiDictionaryTermsValue } from '../dictionary-terms/dictionary-terms.types';
import { ApiNote } from '../notes-calls/notes-calls.types';
import { queryKeys } from '../queryKeys';
import { TranscriptionBlock } from '../transcription-blocks/transcription-blocks.types';
import { TranscriptionSegment } from '../transcription-segments/transcription-segments.types';
import { User } from '../users/users.types';

type UploadCallParams = {
  signedUrl: string;
  file: Blob;
};

type CallTagsParams = {
  callId: number;
  tagId: number;
  origin?: TagOrigin;
};

export const useUploadCallFileToS3BucketMutation = () => {
  const toastIdRef = useRef<Id | null>(null);

  return useMutation({
    mutationFn: async ({ signedUrl, file }: UploadCallParams) => {
      return axios({
        url: signedUrl,
        method: 'PUT',
        data: file,
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (toastIdRef.current === null) {
            toastIdRef.current = createCustomToast({
              message: i18n.t('alertMessage.uploadCallProcessing', {
                ns: 'common',
              }),
              variant: AlertVariant.Loading,
            });
          } else if (progressEvent.total && toastIdRef.current) {
            const ratioCompleted = progressEvent.loaded / progressEvent.total;
            const message: string = i18n.t('alertMessage.uploadCallProcessing', {
              ns: 'common',
            });
            toast.update(toastIdRef.current, {
              progress: ratioCompleted,
              render: ToastMessage({
                alert: {
                  message: `${message} ${Math.round(ratioCompleted * 100)}%`,
                  variant: AlertVariant.Loading,
                },
              }),
            });
          }
        },
      });
    },
    onSuccess: () => {
      toastIdRef.current && clearToast(toastIdRef.current);
      createCustomToast({
        message: i18n.t('alertMessage.uploadCallSuccess', {
          ns: 'common',
        }),
        variant: AlertVariant.Success,
      });
    },
    onError: (error) => {
      toastIdRef.current && clearToast(toastIdRef.current);
      showResponseAlert((error as AxiosError).response, i18n.t('alertMessage.uploadCallError', { ns: 'common' }));
    },
  });
};

export const useUploadCallMutation = () => {
  return useMutation({
    mutationFn: async (metadata: ApiUploadCall) => {
      const { data } = await apiService.API.post<ApiUploadCallResponse>(`${CALLS_API_BASE_URL}`, metadata);
      return data.uploadTo;
    },
  });
};

export const useAddTagToCallMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ callId, tagId, origin }: CallTagsParams) => {
      return apiService.API.post(`${CALLS_API_BASE_URL}/${callId}/tag/${tagId}`, { origin });
    },
    onSuccess: async (_, { callId, origin, tagId }) => {
      updateCallItemInCallSearchCache({
        queryClient,
        callMapper: (call) => {
          if (call.id === callId && origin === TagOrigin.TRUE_PREDICTION) {
            return {
              ...call,
              tags: call.tags.map((tag) => {
                if (tag.id === tagId) {
                  return { ...tag, origin: CallTagState.PREDICTION_VALIDATED };
                }
                return tag;
              }),
            };
          }
          if (call.id === callId) {
            if (origin === TagOrigin.FALSE_PREDICTION) {
              call.tags.splice(
                call.tags.findIndex((tag) => tag.origin === CallTagState.PREDICTED),
                1
              );
            }
            call.tags.push({ id: tagId, origin: CallTagState.USER });
          }
          return call;
        },
      });

      // In case of false prediction, the predicted tag is deleted and replaced by a new tag
      if (origin === TagOrigin.FALSE_PREDICTION) {
        // We need to wait before invalidate query to make sure that the ai call scoring
        // from the call is removed in DB
        await sleep(1000);
        queryClient.invalidateQueries({ queryKey: queryKeys.analytics.aiScoring() });
        queryClient.invalidateQueries({ queryKey: queryKeys.calls.aiCallScoring(callId) });
      }
    },
  });
};

export const useRemoveTagFromCallMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ callId, tagId }: CallTagsParams) => {
      return apiService.API.delete(`${CALLS_API_BASE_URL}/${callId}/tag/${tagId}`);
    },
    onSuccess: async (_, { callId, tagId }) => {
      updateCallItemInCallSearchCache({
        queryClient,
        callMapper: (call) => {
          if (call.id === callId) {
            return {
              ...call,
              tags: call.tags.filter((tag) => tag.id !== tagId),
            };
          }
          return call;
        },
      });

      // We need to wait before invalidate query to make sure that the ai call scoring
      // from the call is removed in DB
      await sleep(1000);
      queryClient.invalidateQueries({ queryKey: queryKeys.calls.aiCallScoring(callId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.analytics.aiScoring() });
    },
  });
};

type AddAiCallScoringReactionParams = {
  callId: number;
  unicode: string;
  answerUuid: string;
  templateUuid: string;
};

export const useAddAiCallScoringReactionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ callId, unicode, answerUuid }: AddAiCallScoringReactionParams) => {
      return apiService.API.post<{ uuid: string }>(`${CALLS_API_BASE_URL}/${callId}/ai-scoring/reactions`, {
        unicode,
        answerUuid,
      });
    },
    onSuccess: (response, { callId, unicode, templateUuid, answerUuid }) => {
      const clonedData = structuredClone(
        queryClient.getQueryData<AiCallScoringDetails[]>(queryKeys.calls.aiCallScoringByTemplate(templateUuid, callId))
      );
      const currentUser = queryClient.getQueryData<User>(queryKeys.users.me());

      if (!clonedData || !currentUser) {
        return;
      }

      clonedData
        .find((question) => question.answer.uuid === answerUuid)
        ?.reactions.push({
          uuid: response.data.uuid,
          unicode,
          user: {
            id: currentUser.id,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
          },
        });

      queryClient.setQueryData(queryKeys.calls.aiCallScoringByTemplate(templateUuid, callId), clonedData);
    },
  });
};

type UpdateAiCallScoringReactionParams = {
  callId: number;
  reactionUuid: string;
  templateUuid: string;
  unicode: string;
};

export const useUpdateAiCallScoringReactionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ callId, reactionUuid, unicode }: UpdateAiCallScoringReactionParams) => {
      return apiService.API.put(`${CALLS_API_BASE_URL}/${callId}/ai-scoring/reactions/${reactionUuid}`, {
        unicode,
      });
    },
    onSuccess: (_, { callId, templateUuid, reactionUuid, unicode }) => {
      const clonedData = structuredClone(
        queryClient.getQueryData<AiCallScoringDetails[]>(queryKeys.calls.aiCallScoringByTemplate(templateUuid, callId))
      );

      if (!clonedData) {
        return;
      }

      queryClient.setQueryData(
        queryKeys.calls.aiCallScoringByTemplate(templateUuid, callId),
        clonedData.map((question) => ({
          ...question,
          reactions: question.reactions.map((reaction) => {
            if (reaction.uuid === reactionUuid) {
              return {
                ...reaction,
                unicode,
              };
            }
            return reaction;
          }),
        }))
      );
    },
  });
};

type DeleteAiCallScoringReactionParams = {
  callId: number;
  reactionUuid: string;
  templateUuid: string;
};

export const useDeleteAiCallScoringReactionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ callId, reactionUuid }: DeleteAiCallScoringReactionParams) => {
      return apiService.API.delete(`${CALLS_API_BASE_URL}/${callId}/ai-scoring/reactions/${reactionUuid}`);
    },
    onSuccess: (_, { callId, templateUuid, reactionUuid }) => {
      const clonedData = structuredClone(
        queryClient.getQueryData<AiCallScoringDetails[]>(queryKeys.calls.aiCallScoringByTemplate(templateUuid, callId))
      );

      if (!clonedData) {
        return;
      }

      queryClient.setQueryData(
        queryKeys.calls.aiCallScoringByTemplate(templateUuid, callId),
        clonedData.map((question) => ({
          ...question,
          reactions: question.reactions.filter((r) => r.uuid !== reactionUuid),
        }))
      );
    },
  });
};

type UpdateCallMutationParams = {
  callId: ApiCallEntity['id'];
  name?: ApiCallEntity['name'];
  userId?: User['id'];
};

export const useUpdateCallMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ callId, userId, name }: UpdateCallMutationParams) => {
      const { data } = await apiService.API.put<ApiCallEntity>(combineRoutes([CALLS_API_BASE_URL, callId.toString()]), {
        userId,
        name,
      });
      return data;
    },
    onSuccess: (data, { callId, userId, name }) => {
      // Currently as we can't return the users directly in the response payload, we refresh the query to get the users
      if (userId) {
        queryClient.invalidateQueries({ queryKey: queryKeys.callDetails.privateCall(callId) });
        return;
      }

      updateCallDetailsCache(callId, () => ({
        name: data.name,
      }));
      updateCallItemInCallSearchCache({
        queryClient,
        callMapper: (call) => {
          if (call.id === callId) {
            return {
              ...call,
              name: data.name,
            };
          }
          return call;
        },
      });
      createToast(`Call ${name ? 'title' : 'attendee'} edited successfully`);
    },
  });
};

type DeleteCallMutationParams = {
  callId: ApiCallEntity['id'];
  name?: ApiCallEntity['name'];
  userId?: User['id'];
};

export const useDeleteCallMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ callId }: DeleteCallMutationParams) => {
      const { status } = await apiService.API.delete<void>(combineRoutes([CALLS_API_BASE_URL, callId.toString()]));
      return status;
    },
    onSuccess: (status, { callId }) => {
      if (status === HttpStatusCode.Ok) {
        const callDetailsQueryKey = queryKeys.callDetails.privateCall(callId);
        updateCallItemInCallSearchCache({
          queryClient,
          callFilter: (call) => call.id !== callId,
        });
        queryClient.setQueryData<PrivateCallDetails>(callDetailsQueryKey, undefined);
      }
    },
  });
};
type AddCallDictionarytermResponse = {
  transcriptionBlocks: TranscriptionBlock[];
  segments: { segments: TranscriptionSegment[] };
  notes: ApiNote[];
};

export const useAddCallDictionaryTermMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ callId, value, key }: { callId: number; value: string; key: string }) => {
      const { data } = await apiService.API.post<AddCallDictionarytermResponse>(
        combineRoutes([CALLS_API_BASE_URL, callId.toString(), 'dictionary-terms']),
        {
          value,
          key,
        }
      );
      return data;
    },
    onSuccess: ({ transcriptionBlocks, segments, notes }, { callId, value, key }) => {
      const queryKey = queryKeys.transcriptionBlocks.callIdAndLanguage(callId);
      /**
       * Update transcript blocks
       */
      queryClient.setQueryData<TranscriptionBlock[]>(queryKey, transcriptionBlocks);
      /**
       * Update segments
       */
      queryClient.setQueryData<TranscriptionSegment[]>(
        queryKeys.transcriptionSegments.callIdAndLanguageAndFormat(callId),
        segments.segments
      );
      /**
       * Update Summary
       */
      queryClient.setQueryData<ApiNote[]>(queryKeys.notesCalls.callId(callId), (currentNotes) => {
        if (!currentNotes?.length) {
          return notes;
        }
        const idToNote = Object.fromEntries(notes.map((note) => [note.id, note]));
        return currentNotes.map((currentNote) => idToNote[currentNote.id] ?? currentNote);
      });
      /**
       * Update dictionary terms
       */
      queryClient.setQueryData<ApiDictionaryTermsValue[]>(queryKeys.dictionaryTerm.all, (dictionaryTerms) => {
        if (!dictionaryTerms) {
          return [];
        }

        if (dictionaryTerms.some((term) => term.value === value)) {
          return dictionaryTerms.map((term) => {
            if (term.value === value) {
              return {
                ...term,
                keys: [...term.keys, key],
              };
            }
            return term;
          });
        }
        dictionaryTerms.push({
          value,
          keys: [key],
          modifiedOn: new Date().toISOString(),
        });
        return dictionaryTerms;
      });
    },
  });
};
