import React, { useEffect, useRef } from 'react';

import { create as Create, DateTime, LockPlugin, RangePlugin } from '@easepick/bundle';
import { secondsToMilliseconds } from 'date-fns';

import { i18n } from '@/translation/i18n';

import { styles } from './custom-styles';
import { RangeCategory } from '../../search-components/search-components.types';

type DatePickerProps = {
  id: string;
  mode: RangeCategory;
  value?: Date | number;
  filters?: {
    start?: Date | number;
    end?: Date | number;
  };
  min?: Date | number;
  max?: Date | number;
  onSelect: (startDate?: Date | number, endDate?: Date | number) => void;
  filter?: boolean;
  onClose?: () => void;
};

const DatePicker = (props: DatePickerProps) => {
  const { id, mode, value, filters, min, max, onSelect, filter = false, onClose } = props;

  const reference = useRef<Create>();

  useEffect(() => {
    // Destroy picker at every mode change before redering a new one
    reference.current?.destroy();

    let isValid = false;
    const element = document.getElementById(id);
    reference.current = new Create({
      element: element ?? '',
      autoApply: filter,
      lang: 'fr-FR',
      css: styles,
      inline: true,
      plugins: [LockPlugin, ...(mode === RangeCategory.BETWEEN ? [RangePlugin] : [])],
      date: value || new DateTime(),
      LockPlugin: {
        minDate: min,
        maxDate: max,
      },
      RangePlugin: {
        startDate: filters?.start ? new DateTime(filters.start) : undefined,
        endDate: filters?.end
          ? new DateTime(
              new Date(
                new Date(filters.end).getTime() + secondsToMilliseconds(new Date(filters.end).getTimezoneOffset() * 60)
              )
            )
          : undefined,
        locale: {
          one: i18n.t('date.day', { ns: 'common' }),
          other: i18n.t('date.days', { ns: 'common' }),
        },
      },
      setup(picker) {
        // Event triggered when view is updated
        picker.on('view', (event) => {
          const { detail, target } = event;
          const applyButton = (target as HTMLElement).getElementsByClassName('apply-button')[0] as HTMLElement;
          if (applyButton) {
            applyButton.textContent = i18n.t('apply', { ns: 'common' });
            const cancelButton = (target as HTMLElement).getElementsByClassName('cancel-button')[0] as HTMLElement;
            if (cancelButton) {
              cancelButton.textContent = i18n.t('cancel', { ns: 'common' });
            }
            if (detail.view === 'Footer' && !isValid) {
              applyButton.setAttribute('disabled', 'true');
              applyButton.classList.add('disabled');
              // Activate button apply
            } else if (isValid && applyButton instanceof HTMLElement) {
              applyButton.removeAttribute('disabled');
              applyButton.classList.remove('disabled');
            }
          }
        });

        // Event triggered when click anywhere
        picker.on('click', (event: CustomEvent) => {
          event.stopPropagation();
          const { target } = event;
          if (target instanceof HTMLElement && picker.isCancelButton(target)) {
            if (picker.datePicked.length === 0) {
              onClose && onClose();
            } else {
              isValid = false;
              picker.trigger('view');
            }
          }
        });

        // Event triggered when range selection is done but not applied
        picker.on('preselect', (event) => {
          const { detail } = event;
          isValid = mode === RangeCategory.BETWEEN ? picker.datePicked.length === 2 : picker.datePicked.length === 1;
          if (isValid) {
            picker.trigger('view', detail);
          }
        });

        // Event triggered when apply button is selected
        picker.on('select', (event) => {
          const { date, start, end } = event.detail;
          let startDate;
          let endDate;
          switch (mode) {
            case RangeCategory.AFTER: {
              startDate = date as Date;
              break;
            }
            case RangeCategory.BEFORE: {
              endDate = date as Date;
              endDate.setHours(23, 59, 59, 999);
              break;
            }
            case RangeCategory.EQUAL: {
              startDate = date as Date;
              endDate = new Date(startDate);
              endDate.setHours(23, 59, 59, 999);
              break;
            }
            default: {
              startDate = start as Date;
              endDate = end as Date;
              endDate.setHours(23, 59, 59, 999);
              break;
            }
          }
          if (startDate || endDate) {
            onSelect(startDate, endDate);
          }
          if (!filter) {
            picker.destroy();
            onClose && onClose();
          }
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return <div id={id} className="hidden" />;
};

export { DatePicker };
