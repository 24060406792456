import { useCallDetails } from '@/entities/call-details/call-details.hooks';
import { isPublicCallDetails } from '@/entities/call-details/call-details.types';
import { useTenantsAiAssistantQuery } from '@/entities/tenant/tenant.queries';

export const useHasAiAssistant = () => {
  const tenantAiAssistantQuery = useTenantsAiAssistantQuery();
  const callDetails = useCallDetails();
  return (
    (tenantAiAssistantQuery.isSuccess && tenantAiAssistantQuery.data.enabled) ||
    (isPublicCallDetails(callDetails) && callDetails.hasAiAssistant)
  );
};
