import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ButtonFilled } from '@/components/common/ui-components/buttons/UIButtonFilled';
import { useCurrentUser } from '@/entities/users/users.hooks';
import { RoutePaths, UserRole } from '@/enums';
import { IntercomIds } from '@/enums/intercom-ids';
import AiStarsFilledIcon from '@/static/AiStars-Filled.svg?react';
import IntercomIcon from '@/static/intercom_filled.svg?react';
import { cn } from '@/utils/utils';

export const DealNoModjoAIContent = ({ className }: { className?: string }) => {
  const currentUser = useCurrentUser();
  const isManager = currentUser?.hasRole(UserRole.MANAGER);
  const navigate = useNavigate();
  const { t } = useTranslation('deals');
  return (
    <div className={cn('flex flex-col', className)}>
      <span className="mb-2 text-base font-semibold">{t('summary.noai.title')}</span>
      <span className="mb-4 text-xs text-grey-800">{t('summary.noai.subtitle')}</span>
      <div>
        <ButtonFilled
          size="small"
          icon={isManager ? <AiStarsFilledIcon /> : <IntercomIcon />}
          id={IntercomIds.ModjoAiDisabled}
          onClick={() => {
            if (isManager) {
              navigate(RoutePaths.SETTINGS_AI_ASSISTANT);
            }
          }}
        >
          {isManager ? t('summary.noai.button.manager') : t('summary.noai.button.user')}
        </ButtonFilled>
      </div>
    </div>
  );
};
