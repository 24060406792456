import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Transition } from '@headlessui/react';
import { Message } from 'ai/react';
import mixpanel from 'mixpanel-browser';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Trans, useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { generatePath, Link } from 'react-router-dom';

import { ShortcutKey } from '@/components/common/ShortcutKey';
import { ButtonFilled } from '@/components/common/ui-components/buttons/UIButtonFilled';
import { IconButtonGhost } from '@/components/common/ui-components/buttons/UIButtonGhost';
import { SimpleTextarea } from '@/components/common/ui-components/inputs/textarea';
import { Collapsible } from '@/components/common/ui-components/layout/Collapsible';
import { useDealAskAnythingStatQuery } from '@/entities/deals/deals.queries';
import { RoutePaths } from '@/enums';
import AiStarsFilledIcon from '@/static/AiStars-Filled.svg?react';
import AiStarsLoadingGif from '@/static/gif/ai_stars.gif';
import EnterKeyIcon from '@/static/icons/arrows/arrow-corner-cw-lb-Line.svg?react';
import ArrowLeftChevronIcon from '@/static/icons/arrows/chevron-left-Line.svg?react';
import ArrowRightChevronIcon from '@/static/icons/arrows/chevron-right-Line.svg?react';
import ArrowUpChevronIcon from '@/static/icons/arrows/chevron-up-Line.svg?react';
import ExternalLinkIcon from '@/static/icons/arrows/external-link-Line.svg?react';
import { Event, EventOrigin } from '@/tracking/events';
import { cn } from '@/utils/utils';

import { AskAnythingSuggestionCategory, AskAnythingSuggestionsQuestionsTranslationKeys } from './ask-anythings.mapping';
import { askAnythingStore } from './askAnythingStore';

type AskAnythingContentProps = {
  dealId: number;
  messages: Message[];
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  input: string;
  setInput: (value: string) => void;
  isLoading: boolean;
  setMessages: (messages: Message[]) => void;
  onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const AskAnythingContent = ({
  dealId,
  messages,
  onSubmit,
  input,
  onInputChange,
  isLoading,
  setMessages,
  setInput,
}: AskAnythingContentProps) => {
  const { t } = useTranslation('deals');
  const askAnythingStatQuery = useDealAskAnythingStatQuery({
    dealId,
    queryOptions: { enabled: !!dealId },
  });
  const [statCount, setStatCount] = useState(1);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const currentIndexSuggestions = askAnythingStore.useSuggestionsCurrentPage();
  const callProcessedOpened = askAnythingStore.useCallProcessedOpen();
  const isMouseInZone = askAnythingStore.useIsMouseInZone();
  const suggestedQuestionsArray = Object.values(
    t('dealView.askMeAnything.suggestedQuestions', { returnObjects: true })
  );

  const userInput = useMemo(() => {
    return messages.filter((m) => m.role === 'user');
  }, [messages]);

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    onSubmit(e);
    return false;
  };

  const handleMouseEnter = () => {
    askAnythingStore.setIsMouseInZone(true);
  };

  const handleMouseLeave = () => {
    askAnythingStore.setIsMouseInZone(false);
  };

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (!textArea) {
      return;
    }
    textArea.style.height = 'auto'; // Reset the height
    if (input.length === 0) {
      textArea.style.height = '36px'; // 24px per line
      return;
    }
    textArea.style.height = `${Math.min(textArea.scrollHeight, 5 * 24)}px`; // 24px per line, max 5 lines
  }, [input]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | number = 0;
    if (!isMouseInZone) {
      timeoutId = setTimeout(() => {
        if (currentIndexSuggestions === 0) {
          askAnythingStore.setSuggestionsCurrentPage(Object.keys(suggestedQuestionsArray).length - 1);
          return;
        }
        askAnythingStore.setSuggestionsCurrentPage(currentIndexSuggestions - 1);
      }, 5000);
    }

    return () => clearTimeout(timeoutId);
  }, [isMouseInZone, currentIndexSuggestions, suggestedQuestionsArray]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | number = 0;
    if (askAnythingStatQuery.data && statCount < askAnythingStatQuery.data.callCount && isLoading) {
      timeoutId = setTimeout(() => {
        setStatCount(statCount + 1);
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [askAnythingStatQuery.data, askAnythingStatQuery.data?.callCount, isLoading, messages.length, statCount]);

  const hasAiMessage = useMemo(() => messages.some((m) => m.role === 'assistant'), [messages]);
  const suggestedQuestions = suggestedQuestionsArray.map((Sq) => Object.values(Sq.questions));
  const currentKey = Object.keys(AskAnythingSuggestionsQuestionsTranslationKeys)[
    currentIndexSuggestions
  ] as AskAnythingSuggestionCategory;

  return (
    <>
      <OverlayScrollbarsComponent className="h-full min-h-0">
        <div className="flex flex-col gap-y-4 px-10 py-6">
          {userInput.length > 0 && (
            <div className="self-end rounded-xl border border-grey-100 px-3 py-2">{userInput[0].content}</div>
          )}
          {messages.length > 0 && (
            <div className="flex flex-col whitespace-pre-wrap rounded-xl border border-grey-100 bg-grey-100 px-3 py-2">
              <div className="flex items-center gap-x-1.5">
                {isLoading ? (
                  <img src={AiStarsLoadingGif} alt="loader" className="icon-small" />
                ) : (
                  <AiStarsFilledIcon className="size-4 fill-orange" />
                )}

                <span className="text-grey-800">
                  {isLoading ? t('dealView.askMeAnything.searchProgress') : t('dealView.askMeAnything.searchComplete')}
                </span>
                {isLoading && (
                  <Trans
                    i18nKey="dealView.askMeAnything.search"
                    ns="deals"
                    values={{
                      count: isLoading ? statCount : askAnythingStatQuery.data?.callCount,
                    }}
                    count={isLoading ? statCount : askAnythingStatQuery.data?.callCount}
                  />
                )}

                {!isLoading && hasAiMessage && (
                  <button
                    type="button"
                    onClick={() => {
                      askAnythingStore.setCallProcessedOpen(!callProcessedOpened);
                    }}
                    className="flex items-center"
                  >
                    <span className="underline">
                      <Trans
                        i18nKey="dealView.askMeAnything.search"
                        ns="deals"
                        values={{
                          count: isLoading ? statCount : askAnythingStatQuery.data?.callCount,
                        }}
                        count={isLoading ? statCount : askAnythingStatQuery.data?.callCount}
                      />
                    </span>
                    <ArrowUpChevronIcon
                      className={cn('icon-small transition-transform rotate-90', {
                        'rotate-180': callProcessedOpened,
                      })}
                    />
                  </button>
                )}
              </div>
              <Collapsible open={callProcessedOpened}>
                <div className="mt-2 flex flex-col justify-start gap-y-1 pl-8">
                  {!isLoading &&
                    callProcessedOpened &&
                    askAnythingStatQuery.data?.calls &&
                    askAnythingStatQuery.data.calls.length > 0 &&
                    askAnythingStatQuery.data.calls.map((call) => {
                      return (
                        <Link
                          to={generatePath(RoutePaths.CALL_DETAILS, { id: String(call.id) })}
                          key={call.id}
                          target="_blank"
                          onClick={() => {
                            mixpanel.track(Event.GO_TO_CALL_DETAILS, {
                              callId: call.id,
                              clickOrigin: EventOrigin.DEAL_VIEW_ASK_ANYTHING,
                            });
                          }}
                          className="group text-left font-semibold hover:underline"
                        >
                          {call.name}
                          <ExternalLinkIcon className="icon-small invisible ml-2 align-middle group-hover:visible" />
                        </Link>
                      );
                    })}
                </div>
              </Collapsible>
              <div className="mt-4">
                {messages.map((m) => (
                  <div className="pl-6" key={m.id}>
                    {m.role === 'assistant' && (
                      <Markdown
                        components={{
                          ol: ({ children }) => <ol className="flex flex-col gap-y-2 whitespace-normal">{children}</ol>,
                        }}
                      >
                        {m.content}
                      </Markdown>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </OverlayScrollbarsComponent>
      {!isLoading && messages.length === 0 && (
        <div
          className="flex flex-col border-t border-t-grey-100 px-10 py-6"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <form onSubmit={handleSubmitForm}>
            <div className="flex flex-col gap-y-3 text-xs">
              <div className="flex items-center justify-between">
                <div>
                  <Trans
                    className="text-grey-800"
                    ns="deals"
                    i18nKey={AskAnythingSuggestionsQuestionsTranslationKeys[currentKey]}
                  />
                </div>
                <div className="flex shrink-0">
                  <IconButtonGhost
                    onClick={() => {
                      mixpanel.track(Event.DEAL_ASK_ANYTHING_CLICK_CARROUSEL_ARROW);
                      if (currentIndexSuggestions === 0) {
                        askAnythingStore.setSuggestionsCurrentPage(Object.keys(suggestedQuestionsArray).length - 1);
                        return;
                      }
                      askAnythingStore.setSuggestionsCurrentPage(currentIndexSuggestions - 1);
                    }}
                    type="button"
                    size="small"
                    icon={<ArrowLeftChevronIcon />}
                  />
                  <IconButtonGhost
                    onClick={() => {
                      mixpanel.track(Event.DEAL_ASK_ANYTHING_CLICK_CARROUSEL_ARROW);
                      if (currentIndexSuggestions === Object.keys(suggestedQuestionsArray).length - 1) {
                        askAnythingStore.setSuggestionsCurrentPage(0);
                        return;
                      }
                      askAnythingStore.setSuggestionsCurrentPage(currentIndexSuggestions + 1);
                    }}
                    type="button"
                    size="small"
                    icon={<ArrowRightChevronIcon />}
                  />
                </div>
              </div>
              {suggestedQuestions.map((sQuestions, index) => (
                <Transition
                  show={currentIndexSuggestions === index}
                  key={sQuestions[index]}
                  enter="transition ease-out duration-500 opacity-0"
                  as="div"
                  enterFrom="translate-x-full opacity-0"
                  enterTo="translate-x-0 opacity-100 "
                  leave="transition ease-in duration-500 opacity-0 hidden"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="flex w-full flex-col rounded-xl border border-grey-100 p-2">
                    {sQuestions.map((question) => (
                      <button
                        className="flex items-start gap-x-2 rounded-lg p-2 text-justify text-xs hover:bg-grey-100"
                        type="submit"
                        key={question}
                        onClick={() => {
                          mixpanel.track(Event.DEAL_ASK_ANYTHING_CLICK_SUGGESTED_QUESTIONS);
                          setInput(question);
                        }}
                      >
                        <AiStarsFilledIcon className="size-4 flex-none fill-orange" />
                        {question}
                      </button>
                    ))}
                  </div>
                </Transition>
              ))}
            </div>
            <button type="submit" ref={buttonSubmitRef} className="hidden" />
            <SimpleTextarea
              className="relative mt-4 h-auto max-h-36 w-full resize-none overflow-y-scroll rounded pr-8"
              size="large"
              autoFocus
              rows={1}
              ref={textAreaRef}
              placeholder={t('dealView.askMeAnything.inputPlaceholder')}
              value={input}
              onChange={onInputChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  mixpanel.track(Event.DEAL_ASK_ANYTHING_SUBMIT_USER_INPUT);
                  buttonSubmitRef.current?.click();
                }
              }}
            >
              <button className="flex-center absolute bottom-2 right-2" disabled={input.length === 0} type="submit">
                <ShortcutKey>
                  <EnterKeyIcon className="size-3 fill-grey-700" />
                </ShortcutKey>
              </button>
            </SimpleTextarea>
          </form>
        </div>
      )}
      {hasAiMessage && !isLoading && (
        <div className="flex flex-col items-center justify-center border-t border-t-grey-100 px-10 py-6">
          <ButtonFilled
            isLoading={isLoading}
            size="small"
            icon={<AiStarsFilledIcon className="icon-small" />}
            iconPosition="start"
            className="pr-3 outline-none md:pr-4.5"
            onClick={() => {
              mixpanel.track(Event.DEAL_ASK_ANYTHING_CLICK_ASK_AGAIN);
              setMessages([]);
              setStatCount(1);
            }}
          >
            {t('dealView.askMeAnything.askNewQuestion')}
          </ButtonFilled>
        </div>
      )}
    </>
  );
};
