import React, { forwardRef } from 'react';

import clsx from 'clsx';

import { deserializeColor } from '@/entities/tags/tags.types';

import { Chip } from '../../ui-components/data-display/chips';
import { ChipProps } from '../../ui-components/data-display/chips/types';

type MultiComboboxTagChipProps = {
  label: string;
  tagIndex: number;
  color?: string;
  textDark?: boolean;
} & Partial<ChipProps>;

const MultiComboboxTagChip = forwardRef<HTMLDivElement, MultiComboboxTagChipProps>(
  ({ label, onDelete, tagIndex, color, textDark, ...others }, reference) => {
    return (
      <Chip
        ref={reference}
        className={clsx(
          'select-none rounded-full',
          !color || textDark ? 'fill-grey-900 text-grey-900' : 'fill-grey-white text-grey-white'
        )}
        label={label}
        onDelete={onDelete}
        style={{ backgroundColor: deserializeColor(color) }}
        data-tag-index={tagIndex}
        {...others}
      />
    );
  }
);

export { MultiComboboxTagChip };
