import React from 'react';

import clsx from 'clsx';
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentProps } from 'overlayscrollbars-react';

import { ProductTourIds } from '@/enums/product-tour-ids';
import { SidebarBase } from '@/layout/SidebarBase';
import { DataTestId } from '@/types/data-test-id';
import { cn } from '@/utils/utils';

import { NavigationLinkButton } from './NavigationLinkButton';
import { NavigationLinkButtonWithViews } from './NavigationLinkButtonWithViews';
import { Notifications } from './Notifications';
import { ProfileMenu } from './ProfileMenu';
import { Search } from './Search';
import { sidebarStore } from './sidebar.store';
import { NAVIGATION_LINK_ITEMS } from './sidebar.utils';
import { SidebarBottomSection } from './SidebarBottomSection';
import { SidebarTopSection } from './SidebarTopSection';
import { useIsCrmSupportedForDeals } from '../deals/useIsCrmSupportedForDeals';

const scrollbarOptions: OverlayScrollbarsComponentProps['options'] = {
  scrollbars: { autoHide: 'scroll' },
  overflow: { x: 'hidden' },
};

const MainSidebar = () => {
  const isSidebarOpen = sidebarStore.useIsSidebarOpen();
  const isCrmSupportedForDeals = useIsCrmSupportedForDeals();

  return (
    <SidebarBase
      className={clsx('transform transition-all duration-300', !isSidebarOpen && 'w-16')}
      productTourId={ProductTourIds.SIDEBAR}
      dataTestId={DataTestId.SIDEBAR_ID}
    >
      <OverlayScrollbarsComponent className="h-full" options={scrollbarOptions}>
        <div className="flex h-full flex-col justify-between">
          <div>
            <SidebarTopSection />

            <div
              className={cn('flex flex-col gap-y-3 px-4', {
                'pt-3': isSidebarOpen,
              })}
            >
              <Search />

              <ul className="flex flex-col gap-y-2">
                <li className="list-none">
                  <Notifications />
                </li>
                {NAVIGATION_LINK_ITEMS.map((item) => {
                  if (item.navigationKey === 'deals' && !isCrmSupportedForDeals) {
                    return null;
                  }
                  if ((item.navigationKey === 'deals' || item.navigationKey === 'explore') && isSidebarOpen) {
                    return (
                      <li key={item.navigationKey} className="list-none">
                        <NavigationLinkButtonWithViews
                          item={item}
                          viewType={item.navigationKey}
                          productTourId={
                            item.navigationKey === 'deals' ? ProductTourIds.DEALS_NAVIGATION_SIDEBAR_BUTTON : undefined
                          }
                        />
                      </li>
                    );
                  }

                  return (
                    <li key={item.navigationKey} className="list-none">
                      <NavigationLinkButton
                        {...item}
                        productTourId={
                          item.navigationKey === 'deals' ? ProductTourIds.DEALS_NAVIGATION_SIDEBAR_BUTTON : undefined
                        }
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <SidebarBottomSection />
        </div>
      </OverlayScrollbarsComponent>

      <ProfileMenu />
    </SidebarBase>
  );
};

MainSidebar.displayName = 'MainSidebar';

export { MainSidebar };
