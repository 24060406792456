import { ModjoDefinedDealObjection, ModjoDefinedDealObjections } from '@/entities/ai-topics/ai-topics.types';
import { LanguageCode } from '@/enums/languages';

export const DealFilterSearchParamKeys = {
  TEAMS: 'teamIds[]',
  USERS: 'userIds[]',
  CLOSE_DATE_PERIOD: 'closeDatePeriod',
  CLOSE_DATE_START: 'startCloseDate',
  CLOSE_DATE_END: 'endCloseDate',
  SEARCH: 'search',
  SORT_FIELD: 'sortField',
  SORT_ORDER: 'sortOrder',
  FORECAST_CATEGORY: 'forecastCategory',
  STATUS: 'status',
  WARNING_UUIDS: 'warningUuids[]',
  OBJECTION_UUIDS: 'objectionUuids[]',
  COLUMN_ORDER: 'columnOrder[]',
  STAGES: 'stages[]',
  TYPES: 'types[]',
};

export const DealFilterSortFields = {
  NAME: 'name',
  WARNING: 'warning',
  OBJECTION_COUNT: 'objectionsCount',
  NEXT_MEETING_DATE: 'nextMeetingDate',
  AMOUNT: 'amount',
  CONTACTS_COUNT: 'contactsCount',
  STAGE: 'stage',
  DEAL_TYPE: 'dealType',
  START_DATE: 'startDate',
  OWNER: 'owner',
};

export const isModjoDefinedDealObjection = (value: string): value is ModjoDefinedDealObjection => {
  return Object.values(ModjoDefinedDealObjections).includes(value as ModjoDefinedDealObjection);
};

export const DealObjectionTypeTranslationKeys = {
  [ModjoDefinedDealObjections.INTEGRATION_AND_TECHNICAL_COMPATIBILITY]:
    'objections.modjoDefined.Integration and technical compatibility',
  [ModjoDefinedDealObjections.PRODUCT_SERVICE_FIT_AND_LIMITATIONS]:
    'objections.modjoDefined.Product / Service Fit & Limitations',
  [ModjoDefinedDealObjections.BUDGET_AND_PRICING]: 'objections.modjoDefined.Budget & Pricing',
  [ModjoDefinedDealObjections.LACK_OF_CLARITY_AND_TRANSPARENCY]:
    'objections.modjoDefined.Lack of clarity & transparency',
  [ModjoDefinedDealObjections.COMMUNICATION_AND_CUSTOMER_SUPPORT]:
    'objections.modjoDefined.Communication and customer support',
  [ModjoDefinedDealObjections.RESISTANCE_TO_CHANGE]: 'objections.modjoDefined.Resistance to Change',
  [ModjoDefinedDealObjections.LACK_OF_NEED_OR_INTEREST]: 'objections.modjoDefined.Lack of Need or Interest',
  [ModjoDefinedDealObjections.INFORMATION_MISSING]: 'objections.modjoDefined.Information Missing',
  [ModjoDefinedDealObjections.AVAILABILITY_AND_TIMING]: 'objections.modjoDefined.Availability and timing',
  [ModjoDefinedDealObjections.DECISION_MAKING_PROCESS]: 'objections.modjoDefined.Decision-Making Process',
} as const satisfies Record<ModjoDefinedDealObjection, string>;

export const defaultColumnSize = (language: string) => {
  return {
    name: 250,
    warning: 120,
    objectionsCount: 145,
    activityTimeline: 390,
    nextMeetingDate: language === LanguageCode.frFR ? 190 : 140,
    amount: 120,
    contactsCount: 130,
    closeDate: language === LanguageCode.frFR ? 170 : 140,
    stage: 160,
    dealType: 120,
    startDate: language === LanguageCode.frFR ? 150 : 130,
    owner: 250,
  };
};
