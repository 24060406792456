export enum AlertVariant {
  Error = 'error',
  Down = 'down',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Loading = 'loading',
  Processing = 'processing',
  EmailDown = 'email-down',
}

export interface Alert {
  message: string | string[];
  variant: AlertVariant;
}
