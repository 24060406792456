import React from 'react';

import { Trans } from 'react-i18next';

import { Notification } from '@/entities/notifications/notifications.types';
import PineappleSmileIcon from '@/static/PineappleSmile.svg?react';
import { i18n } from '@/translation/i18n';

import {
  CommentNotificationType,
  DeprecatedCommentNotificationType,
  isCallReviewNotification,
  isCommentNotification,
  isCrmFillingAnswersAnsweredNotification,
  isLibraryItemNotification,
  isNoteNotification,
  isReactionNotification,
  isReminderNotification,
  NoteNotificationType,
  NotificationTypes,
  ReactionNotificationType,
  ReminderNotificationType,
} from '../notifications-enums';
import {
  CallReviewNotification,
  CommentNotification,
  CrmFillingAnswersAnsweredNotification,
  LibraryItemNotification,
  LikeCommentNotification,
  MentionCommentNotification,
  NoteNotification,
  ReactionCommentNotification,
  ReactionNoteNotification,
  ReminderNotification,
} from '../notifications-models';

const createNoteNotificationDescription = <T extends NoteNotificationType>(notification: NoteNotification<T>) => {
  if (!notification?.note) {
    return null;
  }

  const mentionTeamsNotification = 'teamMentions' in notification.note && (notification.note.teamMentions as string);

  switch (notification.type) {
    case NoteNotificationType.NOTE_MENTION: {
      if (mentionTeamsNotification) {
        return (
          <Trans
            i18nKey="navbar.notifications.note.team"
            values={{ writer: notification.note.writerName, teamMentions: mentionTeamsNotification }}
          />
        );
      }
      return <Trans i18nKey="navbar.notifications.note.you" values={{ writer: notification.note.writerName }} />;
    }
    default: {
      return null;
    }
  }
};

const createReactionNotificationDescription = (
  notification: ReactionCommentNotification | ReactionNoteNotification
) => {
  if (!notification) {
    return null;
  }

  const entity =
    (notification as ReactionCommentNotification).comment || (notification as ReactionNoteNotification).note;

  const notificationType =
    notification.type === ReactionNotificationType.COMMENT_REACTION ? i18n.t('comment') : i18n.t('note');

  if (entity.reactingUserName) {
    return (
      <Trans
        i18nKey="navbar.notifications.reaction.knownReactor"
        values={{ reactor: entity.reactingUserName, type: notificationType }}
      />
    );
  }

  return <Trans i18nKey="navbar.notifications.reaction.unknownReactor" type={notificationType} />;
};

const createCommentNotificationDescription = (
  notification:
    | CommentNotification
    | LikeCommentNotification
    | MentionCommentNotification
    | ReactionCommentNotification
    | ReactionNoteNotification
    | LibraryItemNotification
) => {
  const { comment } = notification as CommentNotification;
  if (!notification || !comment) {
    return null;
  }

  const writer = comment.writerName;

  const likerName = 'likerName' in comment && (notification as LikeCommentNotification).comment.likerName;

  const mentionedTeams = 'teamMentions' in comment && (notification as MentionCommentNotification).comment.teamMentions;

  let description;
  switch (notification.type) {
    case DeprecatedCommentNotificationType.OLD: {
      description = <Trans i18nKey="navbar.notifications.comment.old" values={{ writer }} />;
      break;
    }
    case CommentNotificationType.CALL: {
      description = <Trans i18nKey="navbar.notifications.comment.call" values={{ writer }} />;
      break;
    }
    case CommentNotificationType.REPLY_MENTION: {
      description = <Trans i18nKey="navbar.notifications.comment.replyInThread" values={{ writer }} />;
      break;
    }
    case CommentNotificationType.REPLY_WRITER: {
      description = <Trans i18nKey="navbar.notifications.comment.replyComment" values={{ writer }} />;
      break;
    }
    case CommentNotificationType.MENTION: {
      if (mentionedTeams) {
        description = (
          <Trans i18nKey="navbar.notifications.comment.mentionTeam" values={{ writer, teamMentions: mentionedTeams }} />
        );
      } else {
        description = <Trans i18nKey="navbar.notifications.comment.mentionYou" values={{ writer }} />;
      }
      break;
    }
    case DeprecatedCommentNotificationType.LIKE: {
      if (likerName) {
        description = <Trans i18nKey="navbar.notifications.comment.likeUser" values={{ liker: likerName }} />;
      } else {
        description = i18n.t('navbar.notifications.comment.likeSomeone');
      }
      break;
    }
    case CommentNotificationType.NOTE_REPLY_WRITER: {
      description = <Trans i18nKey="navbar.notifications.comment.noteReply" values={{ writer }} />;
      break;
    }
    case CommentNotificationType.NOTE_BLOCK_REPLY_MENTION:
    case CommentNotificationType.NOTE_BLOCK_REPLY_THREAD: {
      description = <Trans i18nKey="navbar.notifications.comment.noteReplyThread" values={{ writer }} />;
      break;
    }
    default: {
      description = i18n.t('generalErrorMessage');
      break;
    }
  }
  if (!comment?.timestamp || comment.timestamp <= 0) {
    return description;
  }
  return description;
};

const createCallReviewNotificationDescription = ({ review }: CallReviewNotification) => {
  if (!review) {
    return null;
  }
  return (
    <span className="flex items-center gap-x-1">
      {review.reviewerName ? (
        <Trans i18nKey="navbar.notifications.review.user" values={{ reviewer: review.reviewerName }} />
      ) : (
        <Trans i18nKey="navbar.notifications.review.someone" />
      )}
      <PineappleSmileIcon className="icon-small" />
    </span>
  );
};

const createLibraryItemNotificationDescription = (notification: LibraryItemNotification) => {
  if (!notification) {
    return null;
  }
  const {
    libraryItem: { writerName, teamMentions },
  } = notification;

  if (teamMentions) {
    return <Trans i18nKey="navbar.notifications.library.team" values={{ writer: writerName, teamMentions }} />;
  }
  return <Trans i18nKey="navbar.notifications.library.you" values={{ writer: writerName }} />;
};

const createReminderNotificationDescription = ({ type }: ReminderNotification) => {
  if (!type) {
    return null;
  }
  let description;
  switch (type) {
    case ReminderNotificationType.WATCH_LATER: {
      description = <span>{i18n.t('navbar.notifications.watchLater')}</span>;
      break;
    }
    default: {
      description = <span>{i18n.t('navbar.notifications.reminder')}</span>;
      break;
    }
  }
  return description;
};

const createCrmFillingAnswersAnsweredNotificationDescription = ({
  crmFillingAnswersCount,
}: CrmFillingAnswersAnsweredNotification) => {
  return <Trans i18nKey="navbar.notifications.crmFillingAnswersAnswered" values={{ crmFillingAnswersCount }} />;
};

export const createNotificationDescription = (notification: Notification) => {
  if (isReactionNotification(notification)) {
    return createReactionNotificationDescription(notification);
  }

  if (isCommentNotification(notification)) {
    return createCommentNotificationDescription(notification);
  }

  if (isLibraryItemNotification(notification)) {
    return createLibraryItemNotificationDescription(notification);
  }

  if (isNoteNotification(notification)) {
    return createNoteNotificationDescription(notification);
  }

  if (isCallReviewNotification(notification)) {
    return createCallReviewNotificationDescription(notification);
  }

  if (isReminderNotification(notification)) {
    return createReminderNotificationDescription(notification);
  }

  if (isCrmFillingAnswersAnsweredNotification(notification)) {
    return createCrmFillingAnswersAnsweredNotificationDescription(notification);
  }

  if (notification.type === NotificationTypes.DOWNLOAD) {
    return i18n.t('navbar.notifications.download');
  }

  return null;
};
