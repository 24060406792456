import React from 'react';

import { RouteObject, redirect } from 'react-router-dom';

import { LiveNotesPage } from '@/components/settings/livenotes/livenotes-page.helper';
import { SettingsPages } from '@/components/settings/settings-pages';
import { RoutePaths, UserRole } from '@/enums';
import { i18n } from '@/translation/i18n';

import { RoleRoute } from '../RoleRoute';

export const settingsRoutes: RouteObject = {
  path: RoutePaths.SETTINGS,
  async lazy() {
    const { SettingsLayout } = await import('@/components/settings/SettingsLayout/index');
    return {
      Component: SettingsLayout,
      loader: async () => {
        // make sure the namespace is loaded before rendering the related routes
        await i18n.loadNamespaces(['settings']);
        return null;
      },
    };
  },
  children: [
    {
      path: RoutePaths.WILD_CARD,
      index: true,
      loader: () => {
        return redirect(SettingsPages.PROFILE);
      },
    },
    {
      path: SettingsPages.PROFILE,
      async lazy() {
        const { ProfilePage } = await import('@/components/settings/profile');
        return { Component: ProfilePage };
      },
    },
    {
      path: SettingsPages.NOTIFICATIONS,
      async lazy() {
        const { NotificationsSettings } = await import('@/components/settings/notifications');
        return { Component: NotificationsSettings };
      },
    },
    {
      path: SettingsPages.TOPICS,
      async lazy() {
        const { TopicsPage } = await import('@/components/settings/topics');
        return { Component: TopicsPage };
      },
    },
    {
      path: SettingsPages.CUSTOM_VOCABULARY,
      async lazy() {
        const { CustomVocabularyPage } = await import('@/components/settings/custom-vocabulary');
        return { Component: CustomVocabularyPage };
      },
    },
    {
      path: RoutePaths.SETTINGS_AI_ASSISTANT,
      async lazy() {
        const { AiAssistantPage } = await import('@/components/settings/ai-assistant');
        return { Component: AiAssistantPage };
      },
    },
    {
      path: RoutePaths.SETTINGS_AI_NOTES,
      loader: () => {
        return redirect(RoutePaths.SETTINGS_AI_ASSISTANT);
      },
    },
    {
      path: SettingsPages.EMAIL_FOLLOW_UP_TEMPLATES,
      async lazy() {
        const { EmailFollowUpPage } = await import('@/components/settings/email-follow-up/RootPage');
        return { Component: EmailFollowUpPage };
      },
    },
    {
      path: SettingsPages.EMAIL_FOLLOW_UP_TEMPLATE,
      async lazy() {
        const { EmailFollowUpTemplatePage } = await import('@/components/settings/email-follow-up/TemplatePage');
        return { Component: EmailFollowUpTemplatePage };
      },
    },
    {
      path: SettingsPages.EMAIL_FOLLOW_UP_AI_ASSISTANT,
      async lazy() {
        const { EmailFollowUpAiAssistantPage } = await import('@/components/settings/email-follow-up/AiAssistantPage');
        return { Component: EmailFollowUpAiAssistantPage };
      },
    },
    {
      element: <RoleRoute minRoleRequired={UserRole.ADMIN} />,
      children: [
        {
          path: SettingsPages.SUBSCRIPTION,
          async lazy() {
            const { SubscriptionPage } = await import('@/components/settings/subscription');
            return { Component: SubscriptionPage };
          },
        },
      ],
    },
    {
      element: <RoleRoute minRoleRequired={UserRole.MANAGER} />,
      children: [
        {
          path: SettingsPages.TAGS,
          async lazy() {
            const { TagsSettings } = await import('@/components/settings/tags');
            return { Component: TagsSettings };
          },
        },
        {
          path: SettingsPages.USERS,
          async lazy() {
            const { UserSettingsPage } = await import('@/components/settings/users');
            return { Component: UserSettingsPage };
          },
        },
        {
          path: SettingsPages.TEAMS,
          async lazy() {
            const { TeamsPage } = await import('@/components/settings/teams');
            return { Component: TeamsPage };
          },
        },
        {
          path: SettingsPages.DEALS,
          async lazy() {
            const { DealsPage } = await import('@/components/settings/deals/RootPage');
            return { Component: DealsPage };
          },
        },
        {
          path: SettingsPages.DEAL_WARNINGS,
          async lazy() {
            const { DealWarningSetupPage } = await import('@/components/settings/deals/DealWarningSetupPage');
            return {
              Component: DealWarningSetupPage,
              loader: async () => {
                // make sure the namespace is loaded before rendering the related routes
                await i18n.loadNamespaces(['deals']);
                return null;
              },
            };
          },
        },
        {
          path: SettingsPages.CALL_REVIEWS,
          async lazy() {
            const { CallReviewsPage } = await import('@/components/settings/call-reviews');
            return { Component: CallReviewsPage };
          },
        },
        {
          path: SettingsPages.CALL_REVIEW,
          async lazy() {
            const { CallReviewPage } = await import('@/components/settings/call-review');
            return { Component: CallReviewPage };
          },
        },
        {
          path: SettingsPages.INTEGRATIONS,
          async lazy() {
            const { IntegrationsPage } = await import('@/components/settings/integrations');
            return { Component: IntegrationsPage };
          },
        },
        {
          path: SettingsPages.PROVIDER_INTEGRATIONS,
          async lazy() {
            const { ProviderIntegrationPage } = await import(
              '@/components/settings/integrations/integrations-provider'
            );
            return { Component: ProviderIntegrationPage };
          },
        },
        {
          path: SettingsPages.ADVANCED,
          async lazy() {
            const { AdvancedPage } = await import('@/components/settings/advanced/RootPage');
            return { Component: AdvancedPage };
          },
        },
        {
          path: SettingsPages.REMINDER_EMAIL,
          async lazy() {
            const { ReminderEmailEditPage } = await import('@/components/settings/advanced/ReminderEmailEditPage');
            return { Component: ReminderEmailEditPage };
          },
        },
        {
          path: RoutePaths.SETTINGS_DOMAIN_NAMES,
          loader: () => {
            return redirect(RoutePaths.CALL_IMPORT_RESTRICTIONS);
          },
        },
        {
          path: SettingsPages.CALL_IMPORT_RESTRICTIONS,
          async lazy() {
            const { CallImportRestrictionsEditPage } = await import(
              '@/components/settings/advanced/CallImportRestrictionsEditPage'
            );
            return { Component: CallImportRestrictionsEditPage };
          },
        },
        {
          path: SettingsPages.CRM_FILLING_TEMPLATES,
          async lazy() {
            const { CrmFillingPage } = await import('@/components/settings/crm-filling/RootPage');
            return { Component: CrmFillingPage };
          },
        },
        {
          path: SettingsPages.CRM_FILLING_TEMPLATE,
          async lazy() {
            const { CrmFillingTemplatePage } = await import('@/components/settings/crm-filling/TemplatePage');
            return { Component: CrmFillingTemplatePage };
          },
        },
        {
          path: SettingsPages.CRM_FILLING_PLAYGROUND,
          async lazy() {
            const { CrmFillingPlaygroundPage } = await import('@/components/settings/crm-filling/PlaygroundPage');
            return { Component: CrmFillingPlaygroundPage };
          },
        },
        {
          path: SettingsPages.AI_SCORING,
          async lazy() {
            const { AiScoringPage } = await import('@/components/settings/ai-scoring/RootPage');
            return { Component: AiScoringPage };
          },
        },
        {
          path: SettingsPages.AI_SCORING_TEMPLATE,
          async lazy() {
            const { AiScoringTemplatePage } = await import('@/components/settings/ai-scoring/TemplatePage');
            return { Component: AiScoringTemplatePage };
          },
        },
        {
          path: SettingsPages.SUMMARY_TEMPLATES,
          async lazy() {
            const { Summary } = await import('@/components/settings/summary/RootPage');
            return { Component: Summary };
          },
        },
        {
          path: SettingsPages.SUMMARY_TEMPLATE,
          async lazy() {
            const { SummaryTemplatePage } = await import('@/components/settings/summary/TemplatePage');
            return { Component: SummaryTemplatePage };
          },
        },
        {
          path: SettingsPages.HIGHLIGHTS_TEMPLATES,
          async lazy() {
            const { Highlights } = await import('@/components/settings/highlights/RootPage');
            return { Component: Highlights };
          },
        },
        {
          path: SettingsPages.HIGHLIGHTS_TEMPLATE,
          async lazy() {
            const { HighlightsTemplatePage } = await import('@/components/settings/highlights/TemplatePage');
            return { Component: HighlightsTemplatePage };
          },
        },
      ],
    },
    {
      path: SettingsPages.MODJO_EXTENSION,
      async lazy() {
        const { LivenotesInfo } = await import('@/components/settings/livenotes/LivenotesInfo');
        return { Component: LivenotesInfo };
      },
    },
    {
      path: RoutePaths.LIVENOTES,
      children: [
        {
          index: true,
          async lazy() {
            const { LivenotesInfo } = await import('@/components/settings/livenotes/LivenotesInfo');
            return { Component: LivenotesInfo };
          },
        },
        {
          element: <RoleRoute minRoleRequired={UserRole.MANAGER} />,
          children: [
            {
              path: LiveNotesPage.TEMPLATES,
              async lazy() {
                const { NoteTemplateListPage } = await import(
                  '@/components/settings/livenotes/templates/note-template-list'
                );
                return { Component: NoteTemplateListPage };
              },
            },
            {
              path: LiveNotesPage.TEMPLATE_VIEW,
              async lazy() {
                const { NoteTemplateFormPage } = await import(
                  '@/components/settings/livenotes/templates/note-template-form'
                );
                return { Component: NoteTemplateFormPage };
              },
            },
          ],
        },
      ],
    },
    // For legacy reasons, we need to keep these routes
    {
      path: 'nta',
      children: [
        {
          index: true,
          loader: () => {
            return redirect(RoutePaths.MODJO_EXTENSION_SETTINGS);
          },
        },
        {
          element: <RoleRoute minRoleRequired={UserRole.MANAGER} />,
          children: [
            {
              path: LiveNotesPage.TEMPLATES,
              loader: () => {
                return redirect(RoutePaths.LIVENOTES_TEMPLATE_LIST);
              },
            },
            {
              path: LiveNotesPage.TEMPLATE_VIEW,
              async lazy() {
                const { TemplateViewRedirection } = await import(
                  '@/components/settings/livenotes/templates/TemplateViewRedirection'
                );
                return { Component: TemplateViewRedirection };
              },
            },
          ],
        },
        {
          path: LiveNotesPage.NOTES,
          loader: () => {
            return redirect(RoutePaths.LIVENOTES_NOTES_LIST);
          },
        },
      ],
    },
  ],
};
